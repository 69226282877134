header {
  position: fixed;
  padding: 0 1rem;
  width: calc(100% - 2rem);
  background-color: var(--white);
  border-bottom: 1px solid #ECEBEB;
}
header #logo {
  padding: 18px 16px 18px 0;
}
header #burger {
  padding: 6px 3px;
}
header #burger:hover {
  cursor: pointer;
}
header .header-right-part {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  align-items: center;
}
header .header-right-part .user-information {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: fit-content;
  gap: 1rem;
  padding: 4px 1rem 4px 8px;
  border: 1px solid #EBEBEB;
  border-radius: 29px;
}
header .header-right-part .user-information .avatar {
  height: 32px;
}
header .header-right-part .user-information .logout {
  height: 15px;
  cursor: pointer;
}

header.non-active {
  display: none;
}

@media screen and (max-width: 500px) {
  header .header-right-part .Registered-courses {
    display: none;
  }
  header .header-right-part .user-information p {
    display: none;
  }
}

