.course-page {
  max-width: 800px;
}
.course-page h1 {
  color: var(--deepSapphire);
}
.course-page .cours {
  margin: 2rem 0;
  width: 100%;
}
.course-page .btn-exercices {
  background-color: var(--malibu);
  padding: 12px 24px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.course-page .btn-exercices p {
  color: var(--white);
}
.course-page h2 {
  color: var(--deepSapphire);
  margin: 2rem 0 1rem;
}
.course-page p {
  color: var(--tundora);
}
.course-page p strong {
  font-weight: 600;
}
.course-page .example {
  position: relative;
  z-index: -1;
  background: var(--wildSand);
  padding: 10px 10px 10px 30px;
  border-radius: 8px;
  margin-top: 2.5rem;
}
.course-page .example .icon {
  position: relative;
  top: -30px;
  left: -50px;
}
.course-page .example .text-area {
  margin-top: -47px;
}
.course-page .example .text-area p {
  color: var(--black);
}
.course-page .reminder {
  position: relative;
  z-index: -1;
  background: var(--linkWater);
  padding: 10px 10px 10px 30px;
  border-radius: 8px;
  margin-top: 2.5rem;
}
.course-page .reminder .icon {
  position: relative;
  top: -30px;
  left: -50px;
}
.course-page .reminder .text-area {
  margin-top: -47px;
}
.course-page .reminder .text-area p {
  color: var(--black);
}

@media screen and (max-width: 700px) {
  .course-page h1 span {
    font-size: 24px;
    line-height: 29px;
  }
  .course-page .cours {
    margin: 1rem 0;
  }
}
@media screen and (max-width: 500px) {
  .course-page h1 span {
    font-size: 20px;
    line-height: 24px;
  }
}

