.Search-page{
    display: flex;
    flex-direction: column;
    gap: 4rem;
    overflow-x: hidden;
    .filters{
        display: flex;
        flex-direction: row;
        gap: 2rem;
        overflow-x: scroll;
        .filter{
            display: flex;
            border: 1px solid var(--gallery);
            border-radius: 5px;
            font-size: 16px;
            font-weight: 700;
            padding: 8px;
            color: var(--black);
            .icon{
                display: flex;
                align-items: center;
            }
        }
    }
    .competence-list{
        display: flex;
        flex-direction: column;
        gap: 2rem;
        .competence{
            align-items: flex-start;
            .TextArea{
                width: 800px !important;
                margin-left: 2rem;
                .title{
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 24px;
                }
    
                .category{
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 17px;
                    color: var(--scorpion);
                }
    
                .description{
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 17px;
                    color: var(--scorpion);
                }
            }
        }
    }

    .competence:hover{
        background-color: inherit;
        .title{
            text-decoration: underline;
        }
    }
}

@media screen and (max-width: 500px) {
    .Search-page{
        .filters{
            gap: 12px;
        }
        .competence-list{
            .competence{
                .TextArea{
                    .title{
                        font-size: 16px;
                        line-height: 19px;
                    }
                    .description{
                        display: none;}
                }
            }
        } 
    }
}