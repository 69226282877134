.rate {
  display: flex;
  flex-direction: row;
}
.rate .result-rate-star {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
.rate .result-rate {
  margin: 0 16px 0 12px;
}
.rate .nb-rates {
  color: var(--scorpion);
}

