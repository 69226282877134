.sign-in-form{
    display: flex;
    flex-direction: column;
    margin: 6vh auto 0;
    gap: 2rem;
    justify-content: center;
    align-items: center;
    width: 480px;
    img{
        width: 90px;
    }

    .social-login{
        display: flex;
        flex-direction: row;
        gap: 14px;
        button{
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10px 28px;
            background-color: var(--white);
            border: 1px solid #DDDFE0;
            border-radius: 5px;
            img{
                height: 20px;
                width: fit-content
            }
        }
        
    }

    button{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 10px;
        height: 41px;
        cursor: pointer;
        box-sizing: border-box;
        .icon{
            width: 30px;
            height: 30px;
        }
    }

    .separator{
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 12px;
        .line{
            width: 210px;
            height: 1px;
            background-color: var(--scorpion);
        }
    }

    input{
        width: calc(100% - 2rem);
        padding: 10px 1rem;
        border-radius: 5px;
        border: 1px solid #DDDFE0;
        box-sizing: content-box;
    }

    form{
        display: flex;
        flex-direction: column;
        gap: 1rem;
        width: 100%;
        .password-container{
            display: flex;
            flex-direction: row;
            border: 1px solid #DDDFE0;
            box-sizing: content-box;
            border-radius: 5px;

            input{
                width: calc(100% - 2rem);
                border: none;
                border-radius: 5px;
                padding: 10px 1rem;
            }

            .displayPassword{
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;
                cursor: pointer;
                .eye{
                    padding: 0 8px;
                }
            }
        }
    }

    .connexion-button{
        width: calc(100% + 2px);

        padding: 12px 24px;
        border-radius: 5px;
        color: var(--white);
        background-color: var(--deepSapphire);
        border: none;
    }

    .new-user{
        display: flex;
        flex-direction: row;
        gap: 4px;
    }


    a{
        text-decoration: underline;
    }
}

@media screen and (max-width:600px) {
    .sign-in-form{
        width: 300px;
        gap: 1rem;
        h1{
            font-size: 20px;
            font-weight: 500;
        }
        a, p{
            font-size: 12px;
        }
        input{
            font-size: 14px;
        }
    }
}