.method-math{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    max-width: 800px;

    h1{
        color: var(--deepSapphire);
    }

    h2{
        color: var(--deepSapphire);
    }

    .methode{
        position: relative;
        z-index: -1;
        
        border-radius: 8px;
        padding: 10px 10px 10px 30px;
        background-color: var(--oldLace);
        .CheckList{
            position: relative;
            top: -30px;
            left: -59px;
        }

        .text-area{
            margin-top: -47px;
        
            p{
                color: var(--tundora);
            }

            ul{
                display: flex;
                flex-direction: column;
                gap: 10px;
                padding: 0;
                margin: 10px 0 0;
                li{
                    display: flex;
                    flex-direction: row;
                    gap: 10px;
                    .icon{
                        margin-top: 6px ;
                        svg{
                            path{
                                fill: var(--purpleHeart);
                            }
                        }
                    }
                   
                }
            }
        }

    }
}