.competences-page {
  display: flex;
  flex-direction: column;
}
.competences-page .course-summary-and-competences-list {
  display: flex;
  flex-direction: row;
  gap: 2rem;
}
.competences-page .course-summary-and-competences-list .course-summary {
  max-width: 600px;
  height: fit-content;
  box-sizing: border-box;
  outline: solid 2px #f2f2f2;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  gap: 30px;
}
.competences-page .course-summary-and-competences-list .course-summary img {
  width: 60px;
}
.competences-page .course-summary-and-competences-list .course-summary .lesson-details {
  display: flex;
  flex-direction: row;
  gap: 2rem;
}
.competences-page .course-summary-and-competences-list .course-summary .lesson-details .lesson-details-item {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}
.competences-page .course-summary-and-competences-list .course-summary .lesson-details .lesson-details-item img {
  width: 18px;
}
.competences-page .course-summary-and-competences-list .competences-list {
  margin: 7rem 0;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.competences-page .course-summary-and-competences-list .competences-list .competence-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;
  cursor: pointer;
}
.competences-page .course-summary-and-competences-list .competences-list .competence-item .competence-icon {
  height: 56px !important;
  background-color: #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 11px;
  border-radius: 11px;
  border: 6px solid #B3B3B3;
  box-shadow: 0px 13px 0px 0px #808080;
}
.competences-page .course-summary-and-competences-list .competences-list .competence-item .competence-icon p {
  width: 56px;
  text-align: center;
  color: #808080;
}
.competences-page .course-summary-and-competences-list .competences-list .competence-item .competence-icon:hover {
  border: 6px solid #999899;
  background: #B3B4B3;
}

@media screen and (max-width: 768px) {
  .competence-icon {
    height: 46px !important;
    border-radius: 8px;
    border: 6px solid #B3B3B3;
    box-shadow: 0px 13px 0px 0px #808080;
  }
  .competence-icon p {
    width: 56px;
    text-align: center;
    color: #808080;
  }
  .competence-icon:hover {
    border: 6px solid #999899;
    background: #B3B4B3;
  }
}

