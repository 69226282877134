.competence{
    display: flex;
    align-items: center;
    border-radius: 15px;
    img{
        width: 90px;
        height: 90px;
        border-radius: 21px;
    }
    .TextArea{
        margin-left: 1rem;
        width: 224px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        p{
            color: var(--deepSapphire);
        }
        .Details{
            display: flex;
            align-items: center;
            gap: 8px;
            p{
                font-size: 10px;
                font-weight: 400;
                line-height: 12px;
                color: var(--scorpion);
            }
            .Detail{
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 4px;
                .icon{
                    display: flex;
                    align-items: center;
                }
            }
        }
    }
}

.competence:hover{
    cursor: pointer;
    background-color: var(--selagoPurple);
}

@media screen and (max-width: 900px) {
    .competences-page{
        .course-summary-and-competences-list{
            flex-direction: column!important;
            .competences-list{
                margin: 0!important;
            }
        }
    } 
}

@media screen and (max-width: 600px) {
    .competences-page{
        .course-summary-and-competences-list{
            .competences-list{
                .competence-item{
                    .hexagone{
                        height: 60px!important;
                        width: 69px!important;
                        min-width: 69px!important;
                        background-size: 100%!important;
                        p{
                            font-size: 21px;
                        }
                    }
                    .competence-name{
                        font-size: 16px;
                        line-height: 19px;
                    }
                }
            }
            .course-summary{
                padding: 2rem 1rem!important;
            }
        }
    }    
    .competence{
        .Details{
            .Detail{
                p{
                    max-width: 105px;
                }
            }
        }
    }
}

