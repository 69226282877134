.capsule{
    // min-width: 385px;
    img{
        max-width: 400px;
        border-radius: 15px;
    }

    .cta{
        .preliminary-cta{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            margin: 32px 0 28px;
            .fiche-cta{
                padding: 12px 24px;
                background: var(--malibu);
                border-radius: 10px;
                color: var(--white);
            }
            .register-course{
                display: flex;
                flex-direction: row;
                gap: 10px;
                border: 1px solid var(--gallery);
                align-items: center;
                padding: 12px 24px;
                border-radius: 10px;
                img{
                    width: 19px !important;
                    border-radius: 0;
                }

                .icon{
                    display: flex;
                    svg{
                        height: 16px;
                        width: fit-content;
                    }
                    
                }

                p{
                    color: var(--deepSapphire);
                }
            }
        }
        .course-cta{
            background-color: var(--malibu);
            color: var(--white);
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 12px 24px;
            border-radius: 10px;
        }
    }
}