.Registered-courses{
    border: 1px solid var(--gallery);
    padding: 10px;
    border-radius: 50%;
    .icon{
        display: flex;
        svg{
            width: 20px;
            height: 20px;
        }
    }
}

.Registered-courses:hover{
    cursor: pointer;
}