.mobile-breadcrumbs-course-presentation-page{
    display: none;
}
.CoursePresentationPage{
    display: flex;
    flex-direction: row;
    gap: 80px;


    h2{
        color: var(--scorpion);
    }

    .starter-information-course{
        display: flex;
        flex-direction: column;
        gap: 3rem;
        .main-informations{
            display: flex;
            flex-direction: column;
            gap: 2rem;
        }
    }

    .preliminary-cta{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin: 32px 0 28px;
        .fiche-cta{
            padding: 12px 24px;
            background: var(--malibu);
            border-radius: 15px;
            color: var(--white);
        }
        .register-course{
            display: flex;
            flex-direction: row;
            gap: 10px;
            border: 1px solid var(--gallery);
            align-items: center;
            padding: 12px 24px;
            border-radius: 10px;
            .icon{
                display: flex;
                svg{
                    height: 16px;
                    width: fit-content;
                }
            }

            p{
                color: var(--deepSapphire);
            }
        }
    }

    .preliminary-cta-tablette{
        display: none;
    }

    .course-cta{
        background-color: var(--malibu);
        color: var(--white);
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 12px 24px;
        border-radius: 10px;
    }

    .cta-section{
        display: flex;
        flex-direction: column;
        gap: 2rem;
    }
}

@media screen and (max-width: 1000px) {
    .desktop-breadcrumbs-course-presentation-page{
        display: none;
    }
    .mobile-breadcrumbs-course-presentation-page{
        display: flex;
    }
    .CoursePresentationPage{
        flex-direction: column-reverse;
        gap: 3rem;
        .capsule{
            display: flex;
            flex-direction: column;
            .thumbnail{
                width: 600px;
                max-width: none;
                margin: 0 auto;
            }
            .cta{
                display: none;
            }
        }
        .preliminary-cta-tablette{
            display: flex;
            gap: 24px;
            .course-cta{
                width: fill-available;
            }
            .register-course{
                display: flex;
                align-items: center;
                color: var(--deepSapphire);
                gap:10px;
                border: 1px solid var(--gallery);
                border-radius: 10px;
                width: fill-available;
                justify-content: center;

                img{
                    width: 19px !important;
                    border-radius: 0;
                }
            }
        }
    }
}

@media screen and (max-width: 750px) {
    .CoursePresentationPage{
        .capsule{
            align-items: center;
            .thumbnail{
                max-width: 90vw;
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .CoursePresentationPage{
        .preliminary-cta-tablette{
            flex-direction: column;
            .register-course{
                padding: 7.5px 0;
            }
        }
        .starter-information-course{
            .main-informations{
                h1{
                    font-size: 30px;
                    line-height: 36.31px;
                    font-weight: 700;
                }
                h2{
                    font-size: 14px;
                    line-height: 16.94px;
                    font-weight: 500;
                }
            }
        }
    }   
}

@media screen and (max-width: 410px) {
    .CoursePresentationPage{
        .capsule{
            .thumbnail{
                margin: 0;
            }
        }
    }
}