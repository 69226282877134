.course-page{
    max-width: 800px;
    h1{
        color: var(--deepSapphire);
    }

    .cours{
        margin: 2rem 0;
        width: 100%;
    }

    .btn-exercices{
        background-color: var(--malibu);
        padding: 12px 24px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        p{
            color: var(--white);
        }
    }

    h2{
        color: var(--deepSapphire);
        margin: 2rem 0 1rem;
    }

    p{
        color: var(--tundora);
        strong{
            font-weight: 600;
        }
    }

    .example{
        position: relative;
        z-index: -1;
        background: var(--wildSand);
        padding: 10px 10px 10px 30px;
        border-radius: 8px;
        margin-top: 2.5rem;
        .icon{
            position: relative;
            top: -30px;
            left: -50px;
        }
        .text-area{
            margin-top: -47px;
            p{
                color: var(--black);
            }
        }
    }

    .reminder{
        position: relative;
        z-index: -1;
        background: var(--linkWater);
        padding: 10px 10px 10px 30px;
        border-radius: 8px;
        margin-top: 2.5rem;
        .icon{
            position: relative;
            top: -30px;
            left: -50px;
        }
        .text-area{
            margin-top: -47px;
            p{
                color: var(--black);
            }
        }
    }
}

@media screen and (max-width: 700px) {
    .course-page{
        h1{
            span{
                font-size: 24px;
                line-height: 29px;
            }
        }

        .cours{
            margin: 1rem 0;
        }
    }
}

@media screen and (max-width: 500px) {
    .course-page{
        h1{
            span{
                font-size: 20px;
                line-height: 24px;
            }
        }
    }
}