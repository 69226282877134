.landing-page{
    overflow-x: hidden;
    h1{ 
        font-size: 3.5vw;
        font-weight: 700;
        line-height: 3.9vw;
        color: var(--deepSapphire);
        .highlighth1{
            background: linear-gradient(120deg, var(--supernova) 0%, var(--supernova) 100%);
            background-repeat: no-repeat;
            background-size: 100% 40%;
            background-position: 0 90%;
        }
    }

    header{
        position: fixed;
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: inherit;
        -webkit-backdrop-filter: blur(9px);
        backdrop-filter: blur(9px);
        padding: 0 5rem;
        width: calc(100% - 160px);
        max-width: calc(1440px - 160px);
        
        .connexion{
            padding: 8px 12px;
            background-color: var(--white);
            border-radius: 5px;
            height: fit-content;
            margin-left: auto;
            border: 1.5px solid var(--deepSapphire);
            color: var(--deepSapphire);
        }
        .connexion:hover{
            cursor: pointer;
        }
    }

    .main-section{
        padding: 7rem 5rem 2.5rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 2rem;
        img{
            width: 40vw;
            max-width: 480px;
        }
        .text-area{
            max-width: 570px;
            display: flex;
            flex-direction: column;

            .description{
                margin: 2rem 0;
                color: var(--deepSapphire);
                font-size: max(14px, 1.2vw);
            }
            .cta{
                display: flex;
                flex-direction: row;
                gap: 2rem;
                .connexion{
                    border-radius: 5px;
                    background-color: var(--supernova);
                    box-shadow: 1.5px 6px 10px 0px rgba(254, 200, 3, 0.30);
                    display: flex;
                    align-items: center;
                    padding: 12px 16px;
    
                    width: fit-content;
                    font-size: max(14px, 1.5vw);
    
                    border: none;
                }
                .connexion:hover{
                    cursor: pointer;
                }
    
                .cta-button{
                    padding: 12px 16px;
                    align-items: flex-start;
                    min-width: fit-content;
                    width: fit-content;
                    font-size: max(14px, 1.5vw);

                    display: flex;
                    align-items: flex-start;
    
                    border-radius: 5px;
                    border: 2px solid #ECEDEE;
                }
                .cta-button:hover{
                    cursor: pointer;
                }
            }

        }
    }
    
    .modal{
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background-color: rgba(0,0,0,0.5);
        .modal-content{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: fixed;
            padding: 2rem 4.5rem;
            width: fit-content;
            height: fit-content;
            background-color: var(--white);
            top: calc((100vh - 679.5px)/2);
            bottom: calc((100vh - 679.5px)/2);
            right: calc((100vw - 574px)/2);
            left: calc((100vw - 574px)/2);
            border-radius: 5px;
            gap: 40px;

            .modal-header{
                .close{
                    cursor: pointer;
                    position: absolute;
                    top: 20px;
                    right: 30px;
                }
            }
            .modal-body{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 1rem;
                button{
                    cursor: pointer;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    gap: 1rem;
                    padding: 10px 1.5rem;
                    width: 430px;
                    border-radius: 5px;
                    a{
                        width: 100%;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: var(--black);
                    }
                }
                .facebook{
                    background-color: #3C5997;
                    color: var(--white);
                    border: none;
                }
                .google{
                    background-color: var(--white);
                    border: 1px solid #DDDFE0
                }
                .apple{
                    background-color: #000000;
                    color: var(--white);
                    border: none;
                }
                .email{
                    background-color: #F4F4F4;
                    border: none;
                    height: 54px;
                }
            }
            .sign-up{
                display: flex;
                flex-direction: row;
                gap:10px;
            }
        }
    }

    .quick-course-presentation{
        padding: 7rem 5rem;
        background: #F5F8FB;

        .title-description{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;

            gap: 2rem;
            margin-bottom: 5rem;
            h1{
                max-width: 910px;
                text-align: center;
            }
            p{
                color: rgba(8, 18, 113, 0.55);
                font-size: max(18px,1.4vw);
                text-align: center;
                max-width: 800px;
                line-height: max(20px,1.7vw);
            }
        }

        .image-area{
            display: flex;
            justify-content: center;
            img{
                max-width: 657px;
                min-width: 200px;
                width: 40vw;
            }
        }
    }

    .exploration-excellence-section{
        padding: 7rem 5rem;
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 164px;
        .title-description{
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        h1{
            max-width: 910px;
            text-align: center;
        }

        .exploration-excellence-description{
            display: flex;
            justify-content: space-between;
            max-width: 1050px;
            width: 73vw;
            gap: 32px;
            .text-area{
                display: flex;
                flex-direction: column;
                gap: 1rem;
    
                p{
                    color: var(--deepSapphire, #081271);
                    max-width: 450px;
                    width: 32vw;
                    text-align: left;
                }

                .title{
                    font-size: max(24px, 2.22vw);
                    line-height: max(26px, 2.7vw);
                }
        
                .description{
                    color: rgba(8, 18, 113, 0.65);
                    font-size: max(16px,1.4vw);
                    line-height: max(20px,1.7vw);

                    // font-size: max(12px, 1.11vw);
                    // line-height: max(14px, 1.31vw);
                }
            }
            img{
                max-width: 450px;
                min-width: 200px;
                width: 32vw;
            }
        }

        .exploration-excellence-description:nth-child(3){
            flex-direction: row-reverse;
        }

    }

    .cta-section{
        padding: 7rem 5rem;
        display: flex;
        flex-direction: row;
        background: #F5F8FB;
        justify-content: space-between;
        gap: 32px;
        img{
            width: 42vw;
            max-width: 600px;
        }

        .text-area{
            display: flex;
            flex-direction: column;
            gap: 1.5rem;
            max-width: 500px;
            p{
                color: var(--deepSapphire, #081271);
            }

            .title{
                font-size: max(24px, 2.22vw);
                line-height: max(26px, 2.7vw);
                
            }
    
            .description{
                color: rgba(8, 18, 113, 0.65);
                font-size: max(16px,1.4vw);
                line-height: max(20px,1.7vw);

                // font-size: max(12px, 1.11vw);
                // line-height: max(14px, 1.31vw);
            }

            .connexion{
                padding: 12px 16px;
                width: fit-content;
                font-size: max(14px, 1.5vw);
                background-color: var(--supernova);
                border-radius: 24px;
                border: none;
            }
            .connexion:hover{
                cursor: pointer;
            }
        }


    }

    footer{
        background-color: var(--deepSapphire);
        padding: 1.5rem 5rem;

        .footer-container{
            max-width: 1280px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin: 0 auto;
            .text-area{
                display: flex;
                flex-direction: column;
                gap: 4rem;
                .first-part-links{
                    display: grid;
                    grid-template-columns: repeat(2,1fr);
                    gap: 12rem;
                    .column{
                        display: flex;
                        flex-direction: column;
                        gap: 12px;
                        p{
                            color: var(--white);
                        }
                        .title{
                            margin-bottom: 12px;
                        }
                    }
                }
                .social-media{
                    display: flex;
                    align-items: center;
                    gap: 4rem;
                    justify-content: flex-end;
                }
            }
        }
    }
}

@media screen and(max-width: 1300px){
    .landing-page{
        .main-section{
            .text-area{
                .cta{
                    flex-direction: column;
                    gap: 1rem;
                    .connexion, .cta-button{
                        padding: 6px 12px;
                    }
                }
            }
        }

    }
}

@media screen and (max-width: 1064px){
    .landing-page{
        .main-section{
            .text-area{
                .description{
                    margin : 1rem 0 2rem;
                }
                .cta{
                    
                }
            }
        }

        .exploration-excellence-section{
            gap: 80px;
        }

        .cta-section{
            .text-area{
                .connexion{
                    padding: 10px 12px;
                }
            }
        }
    }

}   

@media screen and (max-width: 900px){
    .landing-page{
        header{
            padding: 0 2.5rem;
            width: calc(100% - 5rem);
        }
        .main-section{
            padding: 7rem 2.5rem 2.5rem;
            .text-area{
                .description{
                    margin : 1rem 0;
                }
                .cta{
                    .connexion, .cta-button{
                        padding: 4px 8px;
                    }
                }
            }
        }

        .quick-course-presentation{
            padding: 2rem 2.5rem;
        }

        .exploration-excellence-section{
            padding: 2rem 2.5rem;
        }

        .cta-section{
            padding: 2rem 2.5rem;
            .text-area{
                .connexion, .cta-button{
                    padding: 4px 8px;
                }
            }
        }

        footer{
            padding: 1.5rem 2.5rem;
        }
    }
}

@media screen and (max-width: 768px){
    .landing-page{
        .quick-course-presentation{
            padding: 2rem 2.5rem;
            .title-description{
                flex-direction: column;
                margin-bottom: 2rem;
            }

            .categories{
                overflow-x: scroll;
                gap: 1rem;
                .category{
                    p{
                        width: 115px;
                    }
                }
            }
        }

        .modal{
            .modal-content{
                padding: 1rem 2.5rem;
                top: calc((100vh - 425.5px)/2);
                bottom: calc((100vh - 425.5px)/2);
                right: calc((100vw - 358px)/2);
                left: calc((100vw - 358px)/2);
    
                .h2-m-medium{
                    font-size: 20px;
                }
                .modal-body{                    
                    button{
                        padding: 10px 1.5rem;
                        width: 278px;
                        a{
                            font-size: 14px;
                            font-weight: 500;
                        }
                    }
                }
                .sign-up{
                    p,a{
                        font-size:14px
                    }
                }
            }
        }

        footer{
            .footer-container{
                flex-direction: column;
                gap: 2rem;
                img{
                    height: 40px;
                    width: fit-content;
                }
                .text-area{
                    .social-media{
                        gap: 1.5rem;
                    }
                }
            }
        }
    }

    .exploration-excellence-section, .exploration-excellence-description:nth-child(3){
        .exploration-excellence-description{
            flex-direction: column!important;
            align-items: center ;
            .text-area{
                p{
                    max-width: 600px !important;
                    width: 100% !important;
                    text-align: center !important;
                }
            }
        }
    }
}

@media screen and (max-width: 667px){
    .landing-page{
        h1{
            font-size: 32px;
            line-height: 35px;
        }

        .main-section{
            flex-direction: column;
            align-items: center;
            justify-content: center;        
        }
    }
}

@media screen and (max-width:500px) {
    .landing-page{
        header{
            padding: 0 10px;
            width: calc(100% - 20px);
        }

        .main-section{
            padding: 7rem 10px 2.5rem;

            .text-area{
                .description{
                    font-size: 16px;
                    margin : 1rem 0;
                }
                .connexion{
                    font-size: 16px;
                    padding: 6px 10px;
                }
            }

            img{
                width: 100%;
                max-width: 300px;
            }

        }

        .quick-course-presentation{
            padding: 2rem 10px ;
        }

        .exploration-excellence-section{
            padding: 2rem 10px ;
        }

        .cta-section{
            padding: 2rem 10px ;
        }

        footer{
            padding: 1.5rem 10px;
        }

    }
}