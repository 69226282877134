.objectives {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border: 1px solid var(--gallery);
  border-radius: 15px;
  padding: 1.5rem;
}
.objectives .list-objectives {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px 20px;
  padding: 0;
  margin: 0;
}
.objectives .list-objectives li {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

@media screen and (max-width: 500px) {
  .objectives .list-objectives {
    grid-template-columns: repeat(1, 1fr);
  }
  .objectives p {
    font-size: 14px;
    line-height: 16.94px;
  }
  .objectives ul li p {
    font-size: 12px;
  }
}

