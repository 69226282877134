.rate-submit{
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    .rate{
        display: flex;
        flex-direction: row;
        gap: 8px;
    }
    .submit-your-rate{
        padding: 12px 24px;
        border: 1px solid var(--gallery);
        border-radius: 15px;
        p{
            font-family: "Inter";
            font-size: 16px;
            font-weight: 500;
            line-height: 19px;
            letter-spacing: 0em;
            text-align: left;
        }
    }
}