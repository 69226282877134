.mobile-breadcrumbs-course-presentation-page {
  display: none;
}

.CoursePresentationPage {
  display: flex;
  flex-direction: row;
  gap: 80px;
}
.CoursePresentationPage h2 {
  color: var(--scorpion);
}
.CoursePresentationPage .starter-information-course {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}
.CoursePresentationPage .starter-information-course .main-informations {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.CoursePresentationPage .preliminary-cta {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 32px 0 28px;
}
.CoursePresentationPage .preliminary-cta .fiche-cta {
  padding: 12px 24px;
  background: var(--malibu);
  border-radius: 15px;
  color: var(--white);
}
.CoursePresentationPage .preliminary-cta .register-course {
  display: flex;
  flex-direction: row;
  gap: 10px;
  border: 1px solid var(--gallery);
  align-items: center;
  padding: 12px 24px;
  border-radius: 10px;
}
.CoursePresentationPage .preliminary-cta .register-course .icon {
  display: flex;
}
.CoursePresentationPage .preliminary-cta .register-course .icon svg {
  height: 16px;
  width: fit-content;
}
.CoursePresentationPage .preliminary-cta .register-course p {
  color: var(--deepSapphire);
}
.CoursePresentationPage .preliminary-cta-tablette {
  display: none;
}
.CoursePresentationPage .course-cta {
  background-color: var(--malibu);
  color: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  border-radius: 10px;
}
.CoursePresentationPage .cta-section {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

@media screen and (max-width: 1000px) {
  .desktop-breadcrumbs-course-presentation-page {
    display: none;
  }
  .mobile-breadcrumbs-course-presentation-page {
    display: flex;
  }
  .CoursePresentationPage {
    flex-direction: column-reverse;
    gap: 3rem;
  }
  .CoursePresentationPage .capsule {
    display: flex;
    flex-direction: column;
  }
  .CoursePresentationPage .capsule .thumbnail {
    width: 600px;
    max-width: none;
    margin: 0 auto;
  }
  .CoursePresentationPage .capsule .cta {
    display: none;
  }
  .CoursePresentationPage .preliminary-cta-tablette {
    display: flex;
    gap: 24px;
  }
  .CoursePresentationPage .preliminary-cta-tablette .course-cta {
    width: fill-available;
  }
  .CoursePresentationPage .preliminary-cta-tablette .register-course {
    display: flex;
    align-items: center;
    color: var(--deepSapphire);
    gap: 10px;
    border: 1px solid var(--gallery);
    border-radius: 10px;
    width: fill-available;
    justify-content: center;
  }
  .CoursePresentationPage .preliminary-cta-tablette .register-course img {
    width: 19px !important;
    border-radius: 0;
  }
}
@media screen and (max-width: 750px) {
  .CoursePresentationPage .capsule {
    align-items: center;
  }
  .CoursePresentationPage .capsule .thumbnail {
    max-width: 90vw;
  }
}
@media screen and (max-width: 600px) {
  .CoursePresentationPage .preliminary-cta-tablette {
    flex-direction: column;
  }
  .CoursePresentationPage .preliminary-cta-tablette .register-course {
    padding: 7.5px 0;
  }
  .CoursePresentationPage .starter-information-course .main-informations h1 {
    font-size: 30px;
    line-height: 36.31px;
    font-weight: 700;
  }
  .CoursePresentationPage .starter-information-course .main-informations h2 {
    font-size: 14px;
    line-height: 16.94px;
    font-weight: 500;
  }
}
@media screen and (max-width: 410px) {
  .CoursePresentationPage .capsule .thumbnail {
    margin: 0;
  }
}

