.sign-up-form {
  margin: 4rem auto;
}
.sign-up-form .form-button {
  width: 100%;
  padding: 12px 24px;
  border-radius: 5px;
  color: var(--white);
  background-color: var(--deepSapphire);
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  box-sizing: border-box; /* Permet d'inclure le padding dans la largeur */
}
.sign-up-form .button-under-input {
  margin-top: 1.5rem;
}
.sign-up-form input {
  width: 100%;
  box-sizing: border-box;
  padding: 10px 1rem;
  border-radius: 5px;
  border: 1px solid #DDDFE0;
}
.sign-up-form label {
  color: var(--scorpion);
}
.sign-up-form #phase-0 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 4rem;
  width: fit-content;
  margin: 0 auto;
}
.sign-up-form #phase-0 img {
  width: 30vw;
  max-width: 400px;
}
.sign-up-form #phase-0 .text-area {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 500px;
  gap: 2rem;
}
.sign-up-form #phase-0 .text-area .social-login {
  display: flex;
  flex-direction: row;
  gap: 14px;
}
.sign-up-form #phase-0 .text-area .social-login button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 10px 28px;
  background-color: var(--white);
  border: 1px solid #DDDFE0;
  border-radius: 5px;
}
.sign-up-form #phase-0 .text-area .social-login button img {
  height: 20px;
  width: fit-content;
}
.sign-up-form #phase-0 .text-area button {
  cursor: pointer;
}
.sign-up-form #phase-0 .text-area .separator {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  width: 100%;
}
.sign-up-form #phase-0 .text-area .separator .line {
  width: 100%;
  height: 1px;
  background-color: var(--scorpion);
}
.sign-up-form #phase-0 .text-area .new-user {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}
.sign-up-form #phase-0 .text-area a {
  text-decoration: underline;
}
.sign-up-form #phase-0 .text-area .use-conditions {
  text-align: center;
  color: var(--scorpion);
}
.sign-up-form #phase-0 .text-area .use-conditions a {
  font-weight: 400;
}
.sign-up-form #phase-1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.sign-up-form #phase-1 .header-progress-bar {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  width: 100%;
  margin-bottom: 5rem;
}
.sign-up-form #phase-1 .header-progress-bar img {
  cursor: pointer;
}
.sign-up-form #phase-1 .header-progress-bar .progress-bar-container {
  width: 80vw;
  max-width: 1074px;
  height: 4px;
  background-color: var(--selagoPurple);
  border-radius: 2px;
}
.sign-up-form #phase-1 .header-progress-bar .progress-bar-container .progress-bar {
  height: 100%;
  background-color: var(--deepSapphire);
  border-radius: 2px;
  transition: width 1s ease-in-out;
}
.sign-up-form #phase-1 form {
  display: flex;
  flex-direction: column;
}
.sign-up-form #phase-1 form #phase-1-1 {
  width: 570px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.sign-up-form #phase-1 form #phase-1-1 .information {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.sign-up-form #phase-1 form #phase-1-2 {
  width: 570px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.sign-up-form #phase-1 form #phase-1-2 .level {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 1rem;
  border: 1px solid #DDDFE0;
  gap: 1rem;
  border-radius: 5px;
  cursor: pointer;
}
.sign-up-form #phase-1 form #phase-1-2 .level.active {
  border: 1px solid #FEC803;
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.1);
}
.sign-up-form #phase-1 form #phase-1-3 {
  display: flex;
  flex-direction: row;
  gap: 185px;
}
.sign-up-form #phase-1 form #phase-1-3 .text-area {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 570px;
}
.sign-up-form #phase-1 form #phase-1-3 .text-area h1 {
  color: var(--deepSapphire);
  width: fit-content;
}
.sign-up-form #phase-1 form #phase-1-3 .text-area .quote {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  font-style: italic;
}
.sign-up-form #phase-1 form #phase-1-3 .text-area .quote .bold {
  font-weight: 700;
}
.sign-up-form #phase-1 form #phase-1-3 .text-area button {
  margin-top: 2rem;
}

@media screen and (max-width: 1150px) {
  .sign-up-form #phase-1 form #phase-1-3 {
    flex-direction: column;
    gap: 42px;
  }
  .sign-up-form #phase-1 form #phase-1-3 img {
    max-width: 240px;
  }
}
@media screen and (max-width: 1000px) {
  .sign-up-form #phase-0 {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .sign-up-form #phase-0 h1 {
    font-size: 20px;
    font-weight: 500;
  }
  .sign-up-form #phase-0 a, .sign-up-form #phase-0 p {
    font-size: 12px;
  }
  .sign-up-form #phase-0 input {
    font-size: 14px;
  }
  .sign-up-form #phase-0 img {
    width: 25vw;
  }
}
@media screen and (max-width: 750px) {
  .sign-up-form #phase-1 form #phase-1-1, .sign-up-form #phase-1 form #phase-1-2, .sign-up-form #phase-1 form #phase-1-3 {
    width: 300px;
  }
  .sign-up-form #phase-1 form #phase-1-3 .text-area {
    width: 100%;
  }
}
@media screen and (max-width: 600px) {
  .sign-up-form #phase-0 .text-area {
    width: 300px;
  }
  .sign-up-form #phase-0 .text-area h1 {
    font-size: 30px;
    font-weight: 700;
    text-align: center;
  }
  .sign-up-form #phase-0 .text-area a, .sign-up-form #phase-0 .text-area p {
    font-size: 12px;
  }
  .sign-up-form #phase-0 .text-area input {
    font-size: 14px;
  }
}

