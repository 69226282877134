.sign-in-form {
  display: flex;
  flex-direction: column;
  margin: 6vh auto 0;
  gap: 2rem;
  justify-content: center;
  align-items: center;
  width: 480px;
}
.sign-in-form img {
  width: 90px;
}
.sign-in-form .social-login {
  display: flex;
  flex-direction: row;
  gap: 14px;
}
.sign-in-form .social-login button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 28px;
  background-color: var(--white);
  border: 1px solid #DDDFE0;
  border-radius: 5px;
}
.sign-in-form .social-login button img {
  height: 20px;
  width: fit-content;
}
.sign-in-form button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: 41px;
  cursor: pointer;
  box-sizing: border-box;
}
.sign-in-form button .icon {
  width: 30px;
  height: 30px;
}
.sign-in-form .separator {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
}
.sign-in-form .separator .line {
  width: 210px;
  height: 1px;
  background-color: var(--scorpion);
}
.sign-in-form input {
  width: calc(100% - 2rem);
  padding: 10px 1rem;
  border-radius: 5px;
  border: 1px solid #DDDFE0;
  box-sizing: content-box;
}
.sign-in-form form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}
.sign-in-form form .password-container {
  display: flex;
  flex-direction: row;
  border: 1px solid #DDDFE0;
  box-sizing: content-box;
  border-radius: 5px;
}
.sign-in-form form .password-container input {
  width: calc(100% - 2rem);
  border: none;
  border-radius: 5px;
  padding: 10px 1rem;
}
.sign-in-form form .password-container .displayPassword {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.sign-in-form form .password-container .displayPassword .eye {
  padding: 0 8px;
}
.sign-in-form .connexion-button {
  width: calc(100% + 2px);
  padding: 12px 24px;
  border-radius: 5px;
  color: var(--white);
  background-color: var(--deepSapphire);
  border: none;
}
.sign-in-form .new-user {
  display: flex;
  flex-direction: row;
  gap: 4px;
}
.sign-in-form a {
  text-decoration: underline;
}

@media screen and (max-width: 600px) {
  .sign-in-form {
    width: 300px;
    gap: 1rem;
  }
  .sign-in-form h1 {
    font-size: 20px;
    font-weight: 500;
  }
  .sign-in-form a, .sign-in-form p {
    font-size: 12px;
  }
  .sign-in-form input {
    font-size: 14px;
  }
}

