.landing-page {
  overflow-x: hidden;
}
.landing-page h1 {
  font-size: 3.5vw;
  font-weight: 700;
  line-height: 3.9vw;
  color: var(--deepSapphire);
}
.landing-page h1 .highlighth1 {
  background: linear-gradient(120deg, var(--supernova) 0%, var(--supernova) 100%);
  background-repeat: no-repeat;
  background-size: 100% 40%;
  background-position: 0 90%;
}
.landing-page header {
  position: fixed;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: inherit;
  -webkit-backdrop-filter: blur(9px);
  backdrop-filter: blur(9px);
  padding: 0 5rem;
  width: calc(100% - 160px);
  max-width: 1280px;
}
.landing-page header .connexion {
  padding: 8px 12px;
  background-color: var(--white);
  border-radius: 5px;
  height: fit-content;
  margin-left: auto;
  border: 1.5px solid var(--deepSapphire);
  color: var(--deepSapphire);
}
.landing-page header .connexion:hover {
  cursor: pointer;
}
.landing-page .main-section {
  padding: 7rem 5rem 2.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
}
.landing-page .main-section img {
  width: 40vw;
  max-width: 480px;
}
.landing-page .main-section .text-area {
  max-width: 570px;
  display: flex;
  flex-direction: column;
}
.landing-page .main-section .text-area .description {
  margin: 2rem 0;
  color: var(--deepSapphire);
  font-size: max(14px, 1.2vw);
}
.landing-page .main-section .text-area .cta {
  display: flex;
  flex-direction: row;
  gap: 2rem;
}
.landing-page .main-section .text-area .cta .connexion {
  border-radius: 5px;
  background-color: var(--supernova);
  box-shadow: 1.5px 6px 10px 0px rgba(254, 200, 3, 0.3);
  display: flex;
  align-items: center;
  padding: 12px 16px;
  width: fit-content;
  font-size: max(14px, 1.5vw);
  border: none;
}
.landing-page .main-section .text-area .cta .connexion:hover {
  cursor: pointer;
}
.landing-page .main-section .text-area .cta .cta-button {
  padding: 12px 16px;
  align-items: flex-start;
  min-width: fit-content;
  width: fit-content;
  font-size: max(14px, 1.5vw);
  display: flex;
  align-items: flex-start;
  border-radius: 5px;
  border: 2px solid #ECEDEE;
}
.landing-page .main-section .text-area .cta .cta-button:hover {
  cursor: pointer;
}
.landing-page .modal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
.landing-page .modal .modal-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  padding: 2rem 4.5rem;
  width: fit-content;
  height: fit-content;
  background-color: var(--white);
  top: calc((100vh - 679.5px) / 2);
  bottom: calc((100vh - 679.5px) / 2);
  right: calc((100vw - 574px) / 2);
  left: calc((100vw - 574px) / 2);
  border-radius: 5px;
  gap: 40px;
}
.landing-page .modal .modal-content .modal-header .close {
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 30px;
}
.landing-page .modal .modal-content .modal-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
.landing-page .modal .modal-content .modal-body button {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: 10px 1.5rem;
  width: 430px;
  border-radius: 5px;
}
.landing-page .modal .modal-content .modal-body button a {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--black);
}
.landing-page .modal .modal-content .modal-body .facebook {
  background-color: #3C5997;
  color: var(--white);
  border: none;
}
.landing-page .modal .modal-content .modal-body .google {
  background-color: var(--white);
  border: 1px solid #DDDFE0;
}
.landing-page .modal .modal-content .modal-body .apple {
  background-color: #000000;
  color: var(--white);
  border: none;
}
.landing-page .modal .modal-content .modal-body .email {
  background-color: #F4F4F4;
  border: none;
  height: 54px;
}
.landing-page .modal .modal-content .sign-up {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.landing-page .quick-course-presentation {
  padding: 7rem 5rem;
  background: #F5F8FB;
}
.landing-page .quick-course-presentation .title-description {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  margin-bottom: 5rem;
}
.landing-page .quick-course-presentation .title-description h1 {
  max-width: 910px;
  text-align: center;
}
.landing-page .quick-course-presentation .title-description p {
  color: rgba(8, 18, 113, 0.55);
  font-size: max(18px, 1.4vw);
  text-align: center;
  max-width: 800px;
  line-height: max(20px, 1.7vw);
}
.landing-page .quick-course-presentation .image-area {
  display: flex;
  justify-content: center;
}
.landing-page .quick-course-presentation .image-area img {
  max-width: 657px;
  min-width: 200px;
  width: 40vw;
}
.landing-page .exploration-excellence-section {
  padding: 7rem 5rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 164px;
}
.landing-page .exploration-excellence-section .title-description {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.landing-page .exploration-excellence-section h1 {
  max-width: 910px;
  text-align: center;
}
.landing-page .exploration-excellence-section .exploration-excellence-description {
  display: flex;
  justify-content: space-between;
  max-width: 1050px;
  width: 73vw;
  gap: 32px;
}
.landing-page .exploration-excellence-section .exploration-excellence-description .text-area {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.landing-page .exploration-excellence-section .exploration-excellence-description .text-area p {
  color: var(--deepSapphire, #081271);
  max-width: 450px;
  width: 32vw;
  text-align: left;
}
.landing-page .exploration-excellence-section .exploration-excellence-description .text-area .title {
  font-size: max(24px, 2.22vw);
  line-height: max(26px, 2.7vw);
}
.landing-page .exploration-excellence-section .exploration-excellence-description .text-area .description {
  color: rgba(8, 18, 113, 0.65);
  font-size: max(16px, 1.4vw);
  line-height: max(20px, 1.7vw);
}
.landing-page .exploration-excellence-section .exploration-excellence-description img {
  max-width: 450px;
  min-width: 200px;
  width: 32vw;
}
.landing-page .exploration-excellence-section .exploration-excellence-description:nth-child(3) {
  flex-direction: row-reverse;
}
.landing-page .cta-section {
  padding: 7rem 5rem;
  display: flex;
  flex-direction: row;
  background: #F5F8FB;
  justify-content: space-between;
  gap: 32px;
}
.landing-page .cta-section img {
  width: 42vw;
  max-width: 600px;
}
.landing-page .cta-section .text-area {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  max-width: 500px;
}
.landing-page .cta-section .text-area p {
  color: var(--deepSapphire, #081271);
}
.landing-page .cta-section .text-area .title {
  font-size: max(24px, 2.22vw);
  line-height: max(26px, 2.7vw);
}
.landing-page .cta-section .text-area .description {
  color: rgba(8, 18, 113, 0.65);
  font-size: max(16px, 1.4vw);
  line-height: max(20px, 1.7vw);
}
.landing-page .cta-section .text-area .connexion {
  padding: 12px 16px;
  width: fit-content;
  font-size: max(14px, 1.5vw);
  background-color: var(--supernova);
  border-radius: 24px;
  border: none;
}
.landing-page .cta-section .text-area .connexion:hover {
  cursor: pointer;
}
.landing-page footer {
  background-color: var(--deepSapphire);
  padding: 1.5rem 5rem;
}
.landing-page footer .footer-container {
  max-width: 1280px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 auto;
}
.landing-page footer .footer-container .text-area {
  display: flex;
  flex-direction: column;
  gap: 4rem;
}
.landing-page footer .footer-container .text-area .first-part-links {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12rem;
}
.landing-page footer .footer-container .text-area .first-part-links .column {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.landing-page footer .footer-container .text-area .first-part-links .column p {
  color: var(--white);
}
.landing-page footer .footer-container .text-area .first-part-links .column .title {
  margin-bottom: 12px;
}
.landing-page footer .footer-container .text-area .social-media {
  display: flex;
  align-items: center;
  gap: 4rem;
  justify-content: flex-end;
}

@media screen and (max-width: 1300px) {
  .landing-page .main-section .text-area .cta {
    flex-direction: column;
    gap: 1rem;
  }
  .landing-page .main-section .text-area .cta .connexion, .landing-page .main-section .text-area .cta .cta-button {
    padding: 6px 12px;
  }
}
@media screen and (max-width: 1064px) {
  .landing-page .main-section .text-area .description {
    margin: 1rem 0 2rem;
  }
  .landing-page .exploration-excellence-section {
    gap: 80px;
  }
  .landing-page .cta-section .text-area .connexion {
    padding: 10px 12px;
  }
}
@media screen and (max-width: 900px) {
  .landing-page header {
    padding: 0 2.5rem;
    width: calc(100% - 5rem);
  }
  .landing-page .main-section {
    padding: 7rem 2.5rem 2.5rem;
  }
  .landing-page .main-section .text-area .description {
    margin: 1rem 0;
  }
  .landing-page .main-section .text-area .cta .connexion, .landing-page .main-section .text-area .cta .cta-button {
    padding: 4px 8px;
  }
  .landing-page .quick-course-presentation {
    padding: 2rem 2.5rem;
  }
  .landing-page .exploration-excellence-section {
    padding: 2rem 2.5rem;
  }
  .landing-page .cta-section {
    padding: 2rem 2.5rem;
  }
  .landing-page .cta-section .text-area .connexion, .landing-page .cta-section .text-area .cta-button {
    padding: 4px 8px;
  }
  .landing-page footer {
    padding: 1.5rem 2.5rem;
  }
}
@media screen and (max-width: 768px) {
  .landing-page .quick-course-presentation {
    padding: 2rem 2.5rem;
  }
  .landing-page .quick-course-presentation .title-description {
    flex-direction: column;
    margin-bottom: 2rem;
  }
  .landing-page .quick-course-presentation .categories {
    overflow-x: scroll;
    gap: 1rem;
  }
  .landing-page .quick-course-presentation .categories .category p {
    width: 115px;
  }
  .landing-page .modal .modal-content {
    padding: 1rem 2.5rem;
    top: calc((100vh - 425.5px) / 2);
    bottom: calc((100vh - 425.5px) / 2);
    right: calc((100vw - 358px) / 2);
    left: calc((100vw - 358px) / 2);
  }
  .landing-page .modal .modal-content .h2-m-medium {
    font-size: 20px;
  }
  .landing-page .modal .modal-content .modal-body button {
    padding: 10px 1.5rem;
    width: 278px;
  }
  .landing-page .modal .modal-content .modal-body button a {
    font-size: 14px;
    font-weight: 500;
  }
  .landing-page .modal .modal-content .sign-up p, .landing-page .modal .modal-content .sign-up a {
    font-size: 14px;
  }
  .landing-page footer .footer-container {
    flex-direction: column;
    gap: 2rem;
  }
  .landing-page footer .footer-container img {
    height: 40px;
    width: fit-content;
  }
  .landing-page footer .footer-container .text-area .social-media {
    gap: 1.5rem;
  }
  .exploration-excellence-section .exploration-excellence-description, .exploration-excellence-description:nth-child(3) .exploration-excellence-description {
    flex-direction: column !important;
    align-items: center;
  }
  .exploration-excellence-section .exploration-excellence-description .text-area p, .exploration-excellence-description:nth-child(3) .exploration-excellence-description .text-area p {
    max-width: 600px !important;
    width: 100% !important;
    text-align: center !important;
  }
}
@media screen and (max-width: 667px) {
  .landing-page h1 {
    font-size: 32px;
    line-height: 35px;
  }
  .landing-page .main-section {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (max-width: 500px) {
  .landing-page header {
    padding: 0 10px;
    width: calc(100% - 20px);
  }
  .landing-page .main-section {
    padding: 7rem 10px 2.5rem;
  }
  .landing-page .main-section .text-area .description {
    font-size: 16px;
    margin: 1rem 0;
  }
  .landing-page .main-section .text-area .connexion {
    font-size: 16px;
    padding: 6px 10px;
  }
  .landing-page .main-section img {
    width: 100%;
    max-width: 300px;
  }
  .landing-page .quick-course-presentation {
    padding: 2rem 10px;
  }
  .landing-page .exploration-excellence-section {
    padding: 2rem 10px;
  }
  .landing-page .cta-section {
    padding: 2rem 10px;
  }
  .landing-page footer {
    padding: 1.5rem 10px;
  }
}

