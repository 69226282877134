.fiche-page {
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
}
.fiche-page .button-area {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.fiche-page .button-area .download-button {
  background: var(--malibu);
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding: 12px 24px;
  border-radius: 28px;
  align-items: center;
  justify-content: center;
}
.fiche-page .button-area .download-button p {
  font-family: "Inter";
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--white);
}

