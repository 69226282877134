header{
    position: fixed;
    padding: 0 1rem;
    width: calc(100% - 2rem);
    background-color: var(--white);
    border-bottom: 1px solid #ECEBEB;
    #logo{
        padding: 18px 16px 18px 0;
    }
    #burger{
        padding: 6px 3px;
    }
    #burger:hover{
        cursor: pointer;
    }

    .header-right-part{
        display: flex;
        flex-direction: row;
        gap: 2rem;
        align-items: center;
        .user-information{
            display: flex;
            flex-direction: row;
            align-items: center;
            height: fit-content;
            gap: 1rem;
            padding: 4px 1rem 4px 8px;
            border: 1px solid #EBEBEB;
            border-radius: 29px;
            .avatar{
                height: 32px;
            }
            .logout{
                height: 15px;
                cursor: pointer;
            }
        }
    }
    


}

header.non-active{
    display: none;
}

@media screen and (max-width: 500px) {
    header{
        .header-right-part {
            .Registered-courses{
                display: none;
            }
            .user-information {
                p{
                    display: none;
                }
            }
        }
    }
}