.Search-page {
  display: flex;
  flex-direction: column;
  gap: 4rem;
  overflow-x: hidden;
}
.Search-page .filters {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  overflow-x: scroll;
}
.Search-page .filters .filter {
  display: flex;
  border: 1px solid var(--gallery);
  border-radius: 5px;
  font-size: 16px;
  font-weight: 700;
  padding: 8px;
  color: var(--black);
}
.Search-page .filters .filter .icon {
  display: flex;
  align-items: center;
}
.Search-page .competence-list {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.Search-page .competence-list .competence {
  align-items: flex-start;
}
.Search-page .competence-list .competence .TextArea {
  width: 800px !important;
  margin-left: 2rem;
}
.Search-page .competence-list .competence .TextArea .title {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
}
.Search-page .competence-list .competence .TextArea .category {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: var(--scorpion);
}
.Search-page .competence-list .competence .TextArea .description {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: var(--scorpion);
}
.Search-page .competence:hover {
  background-color: inherit;
}
.Search-page .competence:hover .title {
  text-decoration: underline;
}

@media screen and (max-width: 500px) {
  .Search-page .filters {
    gap: 12px;
  }
  .Search-page .competence-list .competence .TextArea .title {
    font-size: 16px;
    line-height: 19px;
  }
  .Search-page .competence-list .competence .TextArea .description {
    display: none;
  }
}

