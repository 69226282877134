.dash-board{
    display: flex;
    gap: 2.5rem;
    
    .main-section{
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        .main-section-first-line{
            display: flex;
            flex-direction: row;
            gap: 1.5rem;
        }
    }
    .profile-section{
        padding: 2.5rem;
        width: 35vw;
        margin-right: -2.5rem;
        background-color: var(--onahau);
        display: flex;
        flex-direction: column;
        gap: 4rem;
        height: calc(100vh + 2.5rem);
        .section-name{
            letter-spacing: -0.06em;
        }
        .profile-container{
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 2.5rem;
            .profile-main-informations{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                .name{
                    margin: 22px 0 8px;
                }
            }
    
            .profile-kpi{
                background-color: var(--white);
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                gap: 1.5rem;
                width: fit-content;
                padding: 18px;
                border-radius: 20px;
                box-shadow: 0px 18px 55px 0px #00000040;
                .competences{
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    gap: 12px;
                    .picture{
                        width: 38px;
                        height: 38px;
                        border-radius: 50%;
                        background-color: var(--iron);
                    }
                    .text-area{
                        display: flex;
                        flex-direction: column;
                        gap: 5px;
                        width: 92px;
                        .title{
                            color: var(--scorpion);
                        }
                    }


                }
                .vertical-line{
                    background-color: var(--wildSand);
                    width: 1px;
                    height: 100%;
                }
    
            }
        }
    }
}