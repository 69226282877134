.sign-up-form{
    margin: 4rem auto;
    .form-button{
        width: 100%;
        padding: 12px 24px;
        border-radius: 5px;
        color: var(--white);
        background-color: var(--deepSapphire);
        border: none;
        cursor: pointer;
        display: flex;
        justify-content: center;
        box-sizing: border-box; /* Permet d'inclure le padding dans la largeur */
    }
    .button-under-input{
        margin-top: 1.5rem;
    }

    input{
        width: 100%;
        box-sizing: border-box;
        padding: 10px 1rem;
        border-radius: 5px;
        border: 1px solid #DDDFE0;
    }

    label{

        color: var(--scorpion);
    }

    #phase-0{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 4rem;
        width: fit-content;
        margin: 0 auto;
        img{
            width: 30vw;
            max-width: 400px;
        }
        .text-area{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 500px;
            gap: 2rem;
            .social-login{
                display: flex;
                flex-direction: row;
                gap: 14px;
                button{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 8px;
                    padding: 10px 28px;
                    background-color: var(--white);
                    border: 1px solid #DDDFE0;
                    border-radius: 5px;
                    img{
                        height: 20px;
                        width: fit-content
                    }
                }
                
            }
        
            button{
                cursor: pointer;
            }
        
            .separator{
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 12px;
                width: 100%;
                .line{
                    width: 100%;
                    height: 1px;
                    background-color: var(--scorpion);
                }
            }
        
            .new-user{
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 4px;
            }
        
            a{
                text-decoration: underline;
            }
            
            .use-conditions{
                text-align: center;
                color: var(--scorpion);
                a{
                    font-weight: 400;
                }
            }
        }
    }
    #phase-1{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .header-progress-bar{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 1rem;
            width: 100%;
            margin-bottom: 5rem;
            img{
                cursor: pointer;
            }
            .progress-bar-container{
                width: 80vw;
                max-width: 1074px;
                height: 4px;
                background-color: var(--selagoPurple);
                border-radius: 2px;
                .progress-bar{
                    height: 100%;
                    background-color: var(--deepSapphire);
                    border-radius: 2px;
                    transition: width 1s ease-in-out;
                }
            }
        }
        form{
            display: flex;
            flex-direction: column;
            #phase-1-1{
                width: 570px;
                display: flex;
                flex-direction: column;
                gap: 1rem;
                .information{
                    display: flex;
                    flex-direction: column;
                    gap:8px
                }
            }
            #phase-1-2{
                width: 570px;
                display: flex;
                flex-direction: column;
                gap: 1rem;
                .level{
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    padding: 10px 1rem;
                    border: 1px solid #DDDFE0;
                    gap: 1rem;
                    border-radius: 5px;
                    cursor: pointer;
                }
                .level.active{
                    border: 1px solid #FEC803;
                    box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.1);
                }
            }
            #phase-1-3{
                display: flex;
                flex-direction: row;
                gap: 185px;
                .text-area{
                    display: flex;
                    flex-direction: column;
                    gap: 2rem;
                    width: 570px;
                    h1{
                        color: var(--deepSapphire);
                        width: fit-content;
                    }
                    .quote{
                        display: flex;
                        flex-direction: column;
                        gap: 1rem;
                        font-style: italic;
                        .bold{
                            font-weight: 700;
                        }
                    }
                    button{
                        margin-top: 2rem;
                    }
                }
            }
        }
    }
}

@media screen and (max-width:1150px) {
    .sign-up-form{
        #phase-1{
            form{
                #phase-1-3{
                    flex-direction: column;
                    gap: 42px;
                    img{
                        max-width: 240px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width:1000px) {
    .sign-up-form{
        #phase-0{
            display: flex;
            flex-direction: column;
            gap: 1rem;
            h1{
                font-size: 20px;
                font-weight: 500;
            }
            a, p{
                font-size: 12px;
            }
            input{
                font-size: 14px;
            }

            img{
                width: 25vw;
            }
        }
    }
}

@media screen and (max-width:750px) {
    .sign-up-form{
        #phase-1{
            form{
                #phase-1-1, #phase-1-2, #phase-1-3{
                    width: 300px;
                }
                #phase-1-3{
                    .text-area{
                        width: 100%;
                    }
                }
                
            }
        }
    }
}

@media screen and (max-width:600px) {
    .sign-up-form{
        #phase-0{
            .text-area{
                width: 300px;
                h1{
                    font-size: 30px;
                    font-weight: 700;
                    text-align: center;
                }
                a, p{
                    font-size: 12px;
                }
                input{
                    font-size: 14px;
                }
            }
        }
    }
}